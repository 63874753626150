import React, {Component} from "react";
import {Link} from "react-router-dom";


class MainPage extends Component<any, any>{

    render() {
        return <>
            <header className="App-header">
                <p>SWATHINGSOAP</p>
                <p>0.001</p>
                <p>DMITRY EFREMOV</p>
                <a target="_blank" rel="noreferrer" href="https://hipolink.me/swathingsoap">Донат (hipolink)</a>
                <p>PROJECTS I WORKED ON: <br/> <br/> <br/><br/><br/> {/*UG*/}
                    <center>
                        {/*<img className="Portfolio-image" src="https://cdn.cloudflare.steamstatic.com/steam/apps/1901910/header.jpg"></img>*/}
                        <br/>
                        <div>
                            <iframe className="Portfolio-image" src="https://store.steampowered.com/widget/1901910/" frameBorder="0" width="646" height="190"></iframe>
                        </div>
                        <a target="_blank" rel="noreferrer" className="Game-link" href="https://store.steampowered.com/app/1901910/Ultimate_General_American_Revolution/">Ultimate General: American Revolution</a>
                    </center>
                    <center>
                        <a target="_blank" rel="noreferrer" href="https://www.ug1775.com/">Website</a>
                    </center>
                    <center>
                        <a target="_blank" rel="noreferrer" href="https://store.steampowered.com/app/1901910/Ultimate_General_American_Revolution/">Steam</a>
                    </center>
                    <br/> <br/>

                    {/*TUM2*/}
                    <center>
                        {/*<img className="Portfolio-image" src="https://cdn.cloudflare.steamstatic.com/steam/apps/1486950/header.jpg"></img>*/}
                        <br/>
                        <div>
                            <iframe className="Portfolio-image" src="https://store.steampowered.com/widget/1486950/" frameBorder="0" width="646" height="190"></iframe>
                        </div>
                        <a target="_blank" rel="noreferrer" className="Game-link" href="https://store.steampowered.com/app/1486950/The_Underground_Man_2/">The Underground Man 2</a>
                    </center>
                    <center><a target="_blank" href="https://youtu.be/-Gayh8aTU4Y" rel="noreferrer">YouTube</a></center>
                    <center>
                        <a target="_blank" href="https://store.steampowered.com/app/1486950/The_Underground_Man_2/" rel="noreferrer">Steam</a>
                    </center>
                    <center>
                        <a target="_blank" href="https://vkplay.ru/play/game/theundergroundman2" rel="noreferrer">VK Play</a>
                    </center>
                    <br/> <br/>


                    {/*CALL OF LDPR*/}
                    <center>
                        {/*<img className="Portfolio-image" src="https://cdn.cloudflare.steamstatic.com/steam/apps/1449000/library_hero.jpg"></img>*/}
                        <br/>
                        <div>
                            <iframe className="Portfolio-image" src="https://store.steampowered.com/widget/1449000/" frameBorder="0" width="646" height="190"></iframe>
                        </div>
                        <a target="_blank" rel="noreferrer" className="Game-link" href="https://store.steampowered.com/app/1449000/CALL_OF_LDPR/e">CALL OF LDPR</a>
                    </center>
                    <center><a target="_blank" rel="noreferrer" href="https://youtu.be/mbgn7hE2jEI">YouTube</a></center>
                    <center>
                        <a target="_blank" rel="noreferrer" href="https://store.steampowered.com/app/1449000/CALL_OF_LDPR/">Steam</a>
                    </center>
                    <br/> <br/>

                    { /*HPG 4*/}
                    <center>
                        <img className="Portfolio-image" src="https://i.imgur.com/JJhHhHP.jpeg"></img><br/>
                        <a target="_blank" rel="noreferrer" className="Game-link" href="https://mge.family/">MGE</a>
                    </center>
                    <center>
                        <a target="_blank" rel="noreferrer" href="https://youtu.be/FlMpw0M67kk?t=559">YouTube</a>
                    </center>
                    <center>
                        <a target="_blank" rel="noreferrer" href="https://mge.family/">Website</a>
                    </center>
                    <br/> <br/>

                    {/*DAOB*/}
                    <center>
                        <img className="Portfolio-image" src="https://i.imgur.com/xoXS38T.jpeg"></img><br/>
                        <a className="Game-link" target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=XYS-zTtFvno">The Deadly Auction Of Beauties</a>
                    </center>
                    <center>
                        <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=XYS-zTtFvno">YouTube</a>
                    </center>
                    <br/> <br/>

                </p>
            </header>


            {/*<Link to="/">Home</Link>
            <Link to="/wishlist">wishlist</Link>*/}
        </>;
    }
}


export default MainPage