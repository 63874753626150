import React, {Component} from "react";
import {MyButton} from "./ItemsLoader";
import {LoadingImage} from "./App";
import "./Styles/card.css"
import "./Styles/inputField.css"

interface Comment {
    Name:string,
    Comment:string,
}

interface CommentsState{
    commentsDownloaded:boolean
    Comments:Comment[]
}
class Comments extends Component<any, CommentsState>{

    constructor(props:any) {
        super(props);
        this.state = {
            commentsDownloaded:false,
            Comments:[]
        }
    }

    name = "";
    comment = "";


    componentDidMount() {
        this.getComments();
    }

    setUrl(params: Comment): string {

        let url = process.env.REACT_APP_SPREADSHEET_COMMENT_POST as string

        url += this.props.type +"&Name=" + params.Name + "&Comment=" + params.Comment;

        return url;
    }

    async getComments(){

        /*fetcher.fetchBTTVEmotes().then(() => {
            const kappa = fetcher.emotes.get('Kappa').toLink();
            console.log(kappa);
            // https://static-cdn.jtvnw.net/emoticons/v1/25/1.0

            const text = 'Hello :PogChamp:!';
            const parsed = parser.parse(text);
            console.log(parsed);
            // Hello ![PogChamp](https://static-cdn.jtvnw.net/emoticons/v1/88/1.0 "PogChamp")!
        });*/

        console.log( process.env.REACT_APP_SPREADSHEET_COMMENT_GET as string)
        fetch( (process.env.REACT_APP_SPREADSHEET_COMMENT_GET + "_"+this.props.type) as string)
            .then((res) => res.text())
            .then((data) => {
                {
                    var json = JSON.parse(data);
                    var comments: Comment[] = []
                    json.map((value: Array<string>) => {
                        comments.unshift({Name:value[0].toString(), Comment:value[1].toString()} as Comment)
                    })
                    this.setState({commentsDownloaded:true, Comments:comments});
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async leaveComment(){

        (document.getElementById("nameInput") as HTMLInputElement).value = "";
        (document.getElementById("messageInput") as HTMLInputElement).value = "";
        this.setState({commentsDownloaded: false})

        await fetch(
            this.setUrl({Name:this.name, Comment:this.comment}),
            {
                redirect: "follow",
                method: "POST",
            }
        )
            .then((res) => res.text())
            .then((data) => {
                console.log(data);

                this.getComments();
            })
            .catch((error) => {
                console.log(error);
            });

        this.getComments()

    }
    render() {

        var button = <div>
            <input id="nameInput" type="text" name="name" className="input" placeholder="Имя"
                   onChange={(e) => {
                       this.name = e.target.value;
                   }}/>
            <input id="messageInput" type="text" name="message" className="input" placeholder="Собщение"
                   onChange={(e) => {this.comment = e.target.value}}/>
            <div className="Items"><MyButton label="Оставить комментарий" myValue={() => {
                this.leaveComment();
            }}></MyButton></div>

        </div>


        if (this.state.commentsDownloaded) {
            return (
                <div>{button}
                    <div className="cards">
                        {
                            (this.state.Comments).map((c,index) => ShowComment(c,index))
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <div>{button}
                    {LoadingImage()}
                </div>
            )
        }
    }

}


function ShowComment(value: Comment, index:number) {

    var className = ((index%2)==0? "cardElement" : "cardElementRight");
    return <div key={index} className={className}>
        <div className="cardText">{value.Name}</div>
        <div className="cardText">{value.Comment}</div>
    </div>

}

function ConvertEmotes(value:string){



}


export default Comments