import React, {useState} from 'react';
import './Styles/itemsTable.css';
import './Styles/glow.css';
import {LoadingImage} from "./App";
import "./Styles/card.css"
import ItemComments from "./ItemComments";
import {tab} from "@testing-library/user-event/dist/tab";
var TextTruncate = require('react-text-truncate'); // CommonJS or UMD

interface wishlistItem {index:number, name:string, price:string ,image:string, link:string }
type myState = {downloaded:boolean, result:wishlistItem[]};
class ItemsLoader extends React.Component<{},myState> {

    constructor() {
        super({});

        this.state = {
            downloaded: false,
            result: []
        };

    }

    componentDidMount() {
        this.downloadWishList();
    }

    downloadInvoked = false;

    downloadWishList() {

        console.log("test")
        fetch(process.env.REACT_APP_SPREADSHEET_ITEMS_GET as string)
            .then(response =>
            {
               response.text().then(text => {

                   console.log(text)
                   let newResult: wishlistItem[] = []
                   //text = text.replace(/\s/g, "");
                   let values = JSON.parse(text) as Array<Array<string>>;

                   if(values == null || values.length == 0)
                       return;

                   values.forEach((value,index) => {
                       if(index == 0)
                           return;

                       if(value != null && value[0].toString() != "") {
                           newResult.push({index: index, name:value[0].toString(),price:value[1].toString(),image:value[2].toString(),link:value[3].toString()})
                       }
                   })

                   newResult.forEach((value) => {
                       console.log(value.name)
                   })

                   this.setState({downloaded:true, result:newResult});
                   console.log(this)
               })
            }).catch(error => {
                console.log(error)
        })


    }

    render() {

        if(this.state.downloaded) {
            return <ShowAllItems result={this.state.result} />
        }else{
            return LoadingImage()
        }
    }


}

function ShowAllItems({result} : {result: wishlistItem[]}) {

    return (<div>{result.map((value) =>
        ShowItem(value)
    )}</div>)

}

function ShowItem(item: wishlistItem) {

    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }

    let tileClass = (item.index % 2 == 0 ? "Tile" : "TileLeft")

    let itemImage = <td className="ImageTd" rowSpan={3}><img className="ItemImage" src={item.image} alt="No image"
                                                             width="150"/></td>;
    let itemName = <td className="ItemName"><TextTruncate
        line={4}
        text={item.name}
        element="span"
    /></td>;

    let ruble = <>{item.price.includes("₸") ? <></> : <span>₽</span>}</>
    let itemPrice = <td><p className="ItemPrice">{item.price} {item.price != "" && ruble}</p></td>
    let itemButton = <td>{item.link != "" && <MyButton label="Купить" myValue={() => {
        window.location.href = item.link;
    }}/>}</td>


    let pcTable = <div>
        <div key={item.index} className={tileClass}>
            <table className="ItemTable">
                <tbody>
                <tr>
                    <td className="ImageTd" rowSpan={3}>
                        <img className="ItemImage" src={item.image} alt="No image" width="150"/></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td className="ItemName"><TextTruncate line={4} text={item.name} element="span"/></td>
                    <td><p className="ItemPrice">{item.price} {item.price != "" && ruble}</p></td>
                    <td>{item.link != "" && <MyButton label="Купить" myValue={() => {
                        window.location.href = item.link;
                    }}/>}</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            </table></div>
            {/*<ItemComments/>*/}
        </div>


            let mobileTable = <div>
            <div key={item.index} className={tileClass}>
            <table className="ItemTableMobile">
                <tbody>
                <tr>
                    <td className="ImageTd" rowSpan={4} colSpan={5}>
                        <img className="ItemImage" src={item.image} alt="No image" width="150"/></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td className="ItemNameMobile" colSpan={4}><TextTruncate line={4} text={item.name} element="span"/></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td className="ItemPriceMobile" colSpan={3}><p >{item.price} {item.price != "" && <span>₽</span>}</p></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td>{item.link != "" && <MyButton label="Купить" myValue={() => {
                        window.location.href = item.link;
                    }}/>}</td>
                    <td></td>
                </tr>
                </tbody>
            </table>
            </div>
                {/*<ItemComments/>*/}
            </div>

    let table;
    if (getWindowDimensions().width < 600) {
        table = mobileTable;
    }else{
        table = pcTable;
    }

    return(<>{table}</>)
}

interface MyButtonParams {
    label: string
    myValue: () => void;
}

export function MyButton(fn: MyButtonParams) {


    return (
        <button
            className="Glow"
            type="button"
            onClick={(e) => {
                fn.myValue();
            }}
        >{fn.label}</button>
    );
}


export default ItemsLoader;