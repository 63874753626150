import React, {Component} from "react";
import './Styles/LampaFeed.css';
import {MyButton} from "../ItemsLoader";



class LaraItem extends Component<string[], {description:boolean}>{

	constructor(info :string[]) {
		super(info);
		this.state = {
			description: false
		}

	}

	componentDidMount() {
		this.openDesc = this.openDesc.bind(this);
		this.closeDesc = this.closeDesc.bind(this);
	}


	openDesc(){
		console.log("b")
		console.log(this.state.description)
		this.setState({description:true})
		this.state = {description:true}
		console.log(this.state.description)
	}

	closeDesc(){
		console.log("a")
		console.log(this.state.description)
		this.setState({description:false})
		this.state = {description:false}
	}

	render() {

		let desc = <></>
		if(!this.state.description)
		{
			desc = <><MyButton label="Открыть описание" myValue={() => this.openDesc()}/>
			</>

		} else {
			desc = <>
				<MyButton label="Скрыть описание" myValue={() => this.closeDesc()}/>
				<p className="laraDesc">{this.props[3]}</p>
			</>;
		}

		let record = <></>
		if(this.props[6] != "") {
			record = <><a href={this.props[6] as string}>Запись прохождения</a><br/><br/></>;
		}

		return (<div className={"laraItem" + this.props[5]}>
			<p>{this.state.description}</p>
			<p>{this.props[1]}</p>
			<p>{this.props[2]}</p>
			{desc}
			<p>Платформа: {this.props[4]}</p>
			{record}
			<img src={this.props[0]} className="laraImg"></img>
		</div>)
	}
}


export default LaraItem