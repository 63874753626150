import React, {Component} from "react";
import {LoadingImage} from "../App";
import {wait} from "@testing-library/user-event/dist/utils";

type myState = {downloaded:boolean, downloading:boolean, result:albumInfo[]};
interface albumInfo{
	author: string,
	name: string,
	rate: number,
	year: number,
	opinion: string,
	image: string,

}
class Lvchk extends Component<any, myState>{

	constructor() {
		super("");
		this.state = {
			downloaded:false,
			result:[],
			downloading:false
		}
	}

	getFindLink(artist: string, name: string){
		return (process.env.REACT_APP_LASTFM_GET_ALBUM as string)
			.replace("{0}",artist)
			.replace("{1}",name)
	}

	componentDidMount() {
		document.title = "lvchk music albums";


		let topLink = document.querySelector("#topLink");
		console.log(topLink)
		if(topLink != null) {
			topLink.setAttribute("href", "https://t.me/mrlevchuk")
		}

		this.downloadMusic();
		this.setState({downloading:true})
		if(!this.state.downloading)
			console.log("component C mounted");
	}

	downloadInvoked = false;


	downloadMusic() {

		if(this.state.downloaded)
			return
		if(this.state.downloading)
			return

		console.log("test")
		fetch(process.env.REACT_APP_SPREADSHEET_LVCHK_MUSIC as string)
			.then(response =>
			{
				response.text().then(text => {

					let newResult: albumInfo[] = []
					let values = JSON.parse(text) as Array<Array<string>>;

					if(values == null || values.length == 0)
						return;

					values.forEach((value,index) => {
						if(index == 0)
							return;
						if(index == 1)
							return;
						if(index == 2)
							return;

						if(value != null) {
							newResult.push(
								{
									author: value[2],
									name: value[1],
									year: Number(value[3]),
									opinion: value[4],
									rate:Number(value[5]),
									image:""
								})
						}
					})

					let counter = 0
					newResult.forEach((value, index) => {

						if(counter < 1000) {
							if (value.author !== "") {
								fetch(this.getFindLink(value.author, value.name)).then(response => response.text().then(text => {

									let jsoon = JSON.parse(text)
									if(jsoon.error == null) {
										let img = jsoon.album.image[2]["#text"] as string;
										console.log(img)
										newResult[index].image = img;
										this.setState({downloaded:true, result:newResult});
									}
								}))
							}
							counter++;
						}

					})

					this.setState({downloaded:true, result:newResult});

				})
			}).catch(error => {
			console.log(error)
		})


	}


	album(name: albumInfo){
		if(name.image !== "") {
			return <p><img src={name.image}></img>{name.name}</p>
		}else{
			return <div>{name.name}</div>
		}
	}

	render() {
		let header = <header className="App-header">
			<p>LAMPAFEED</p>
			<p>Прохождение Tomb Raider от стримера LampaFeeD ^^</p>
		</header>;


		let games = LoadingImage();

		console.log(this.state.result[1]?.image)

		if (this.state.downloaded) {
			games = <div>{this.state.result.map((value) => this.album(value))}</div>
		}


		return(games);
	}


}


export default Lvchk