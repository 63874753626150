import React, {Component} from "react";
import './Styles/LampaFeed.css';
import LaraItem from "./LaraItem";
import {LoadingImage} from "../App";
import ReactTwitchEmbedVideo from "react-twitch-embed-video"


type myState = {downloaded:boolean, result:string[][]};
class LampaFeed extends Component<any, myState>{

	constructor() {
		super("");
		this.state = {
			downloaded:false,
			result:[]
		}
	}

	componentDidMount() {
		document.title = "LampaFeed";
		let link = document.querySelector("link[rel~='icon']");
		if(link != null) {
			link.setAttribute("href", "./lampa.ico");
		}

		let top = document.querySelector(".Logo-holder");
		console.log(top)
		if(top != null) {
			top.setAttribute("src", "./lampa.ico")
		}

		let topLink = document.querySelector("#topLink");
		console.log(topLink)
		if(topLink != null) {
			topLink.setAttribute("href", "https://www.twitch.tv/lampafeed")
		}

		this.downloadWishList();
	}

	downloadInvoked = false;

	downloadWishList() {

		if(this.state.downloaded)
			return

		console.log("test")
		fetch(process.env.REACT_APP_SPREADSHEET_LAMPA_LARA_GET as string)
			.then(response =>
			{
				response.text().then(text => {

					console.log(text)
					let newResult: string[][] = []
					let values = JSON.parse(text) as Array<Array<string>>;

					if(values == null || values.length == 0)
						return;

					values.forEach((value,index) => {
						if(index == 0)
							return;

						if(value != null) {
							newResult.push(value)

						}
					})

					newResult.forEach((value) => {
						console.log(value[0])
					})

					this.setState({downloaded:true, result:newResult});
					console.log(this)
				})
			}).catch(error => {
			console.log(error)
		})


	}

	gameElement(gameInfo: string[]){
		return <LaraItem {...gameInfo}/>
	}

	render() {
		let header = <header className="App-header">
			<p>LAMPAFEED</p>
			<p>Прохождение Tomb Raider от стримера LampaFeeD ^^</p>
		</header>;


		let games = LoadingImage();

		if (this.state.downloaded) {
			games = <div>{this.state.result.map((value) => this.gameElement(value))}</div>
		}

		console.log(games)

		return (<div>
			{header}
			<ReactTwitchEmbedVideo channel="lampafeed" chat="mobile"  height="600" width="620" /><br/>
			<div>{games}</div>

			<br/><br/><br/><br/><br/><br/>
		</div>);
	}


}


export default LampaFeed