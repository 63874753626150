import React, {Component} from "react";
import ItemsLoader from "./ItemsLoader";
import Comments from "./Comments";


class Wishlist extends Component<any, any>{

    render() {
        return <>
            <header className="App-header">
                <p>WISHLIST</p>
            </header>

            <div className="Items">
                <ItemsLoader/>
            </div>
            <header className="App-header">
                <p>КОММЕНТАРИИ:</p>
            </header>
            <div className="Comments">
                <Comments type="wishlist"/>
            </div>
        </>;
    }
}


export default Wishlist